/* bryntum_styles.css */
/* Importa el tema de Bryntum */
@import "@bryntum/scheduler/scheduler.stockholm.css";

/* Asegúrate de que el contenedor principal tenga la altura completa de la vista */
#root {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Asegúrate de que el contenedor del calendario ocupe todo el espacio disponible */
.card-calendar-height {
  height: 100vh !important;
}
